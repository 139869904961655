.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 60px 1fr;
    grid-gap: 30px;
    align-items: start;
    justify-items: center;
}

.wrapper div {
    width: 100%;
}

/*.wrapper div input,
.wrapper div input:focus {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #B4B4B4;
    padding: 0 10px;
}*/

.wrapper div button {
    width: 100%;
}

.button {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #D90015;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.button:hover {
    background: #6e0f0f;
}

@media (max-width: 600px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}
