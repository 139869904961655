body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row p strong img {
  width: 300px;
}

.row a,
.row a:visited {
  color: #d90015;
}

.row a:hover {
  color: #9d0917;
}

.MuiDrawer-paperAnchorTop {
  background-color: unset!important;
}

.rec-carousel-wrapper .rec-carousel .rec-arrow-left {
  background-color: rgba(161,161,161,0.3);
  border-radius: 0;
  margin-right: -20px;
  width: 20px;
  min-width: 20px;
  height: 60px;
  z-index: 100;
  margin-left: -10px;
  border: 0;
}
.rec-carousel-wrapper .rec-carousel .rec-arrow-right {
  background-color: rgba(161,161,161,0.3);
  border-radius: 0;
  margin-left: -20px;
  width: 20px;
  min-width: 20px;
  height: 60px;
  z-index: 100;
  margin-right: -10px;
  border: 0;
}

.rec-carousel-wrapper .rec-carousel .rec-arrow-left:hover:enabled,
.rec-carousel-wrapper .rec-carousel .rec-arrow-right:hover:enabled {
  background-color: rgba(217,0,21,.9);
}

.gEIJqc:hover:enabled,
.gEIJqc:focus:enabled,
.rec-carousel-wrapper .rec-dot_active:focus {
  color: #fff;
  background-color: rgba(161,161,161,1);
  box-shadow: 0 0 2px 0 #333;
}

.rec-carousel-wrapper .rec-dot_active,
.rec-carousel-wrapper .rec-dot_active:hover {
  background-color: rgba(217,0,21,.5);
  box-shadow: 0 0 1px 3px rgba(217,0,21,1);
}
