.wrapper {
    width: 100%;
    background: #fff;
    color: #000;
    border-radius: 3px;
}

.wrapper:hover {
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}

.wrapperMin {
    width: 100%;
    background: #fff;
    color: #000;
    border-radius: 3px;
    position: relative;
}

.wrapperMin .cardList {
    display: none;
    transition: display 1s;
}

.wrapperMin .cardTitle {
    display: none;
}


.cardContent {
    display: grid;
    grid-template-areas:
            "cardTitle cardTitle"
            "cardImage cardList";
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 30px 1fr;
    grid-gap: 20px;
    align-items: center;
    padding: 20px;
}

.wrapperMin .cardContent {
    grid-template-areas:
            "cardImage";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    padding: 10px;
    transition: width 0.3s cubic-bezier(1, 1, 1, 1) 100ms;
}

.wrapperMin .cardContent:hover {
    background: #fff;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
    min-width: 100%;
    width: auto;
    grid-template-areas:
            "cardImage"
            "cardList";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    justify-items: center;
    position: absolute;
    z-index: 100;
}

.wrapperMin .cardContent:hover .cardList {
    display: block;
    justify-self: center;
}


.cardTitle {
    width: 100%;
    grid-area: cardTitle;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.cardImage {
    grid-area: cardImage;
    width: 100px;
    justify-self: center;
}

.wrapperMin .cardImage {
    width: 80px;
}


.cardList {
    grid-area: cardList;
}

.wrapperMin .cardList {
    grid-area: cardList;
    align-self: center;
    text-align: center;
}

.cardList a,
.cardList a:visited {
    color: #04070F;
}

.cardList a:hover {
    text-decoration: underline;
    color: black;
}

.cardList li {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    cursor: pointer;
}

.cardList li:hover {
    text-decoration: underline;
    color: black;
}

@media (max-width: 950px) {
    .wrapperMin .cardImage {
        width: 60px;
    }
}

@media (max-width: 900px) {
    .cardImage {
        width: 200px;
    }
    .wrapperMin .cardImage {
        width: 60px;
    }
}

@media (max-width: 600px) {
    .cardImage {
        width: 140px;
    }
    .wrapperMin .cardImage {
        width: 50px;
    }
}

@media (max-width: 500px) {
    .cardImage {
        width: 100px;
    }
    .wrapperMin .cardImage {
        width: 40px;
    }
}
