.wrapper {
    width: 100%;
    display: grid;
    grid-template-areas:
            "topMenu"
            "topBar";
    grid-template-columns: 1fr;
    grid-template-rows: 60px 100px;
    grid-gap: 0;
}

.topMenu {
    grid-area: topMenu;
}

.topBar {
    grid-area: topBar;
}
