.wrapper {
    width: 100%;
    display: grid;
    background: #1C1C1C;
    color: white;
    grid-template-areas:
            ". generalMenu cartMenu userMenu .";
    grid-template-columns: 10% 1fr auto auto 10%;
    grid-template-rows: 60px;
    grid-gap: 20px;
    align-items: center;
}

.generalMenu {
    grid-area: generalMenu;
    justify-self: left;
}

.menuGrid {
    display: grid;
    grid-template-columns: 80px 120px 160px 90px 90px auto;
    grid-template-rows: 60px;
    grid-gap: 0;
}

.menuGrid span {
    height: 57px;
    display: grid;
    align-items: center;
    text-align: center;
    border-bottom: 3px solid #1C1C1C;
    font-size: 14px;
    font-weight: 500;
}

.menuGrid span:hover {
    cursor: pointer;
    background-color: #333;
    border-bottom: 3px solid #D90015;
    transition: margin-bottom 0.5s, background-color 1s;
}

.menuGrid span a {

}

.userMenu {
    grid-area: userMenu;
    display: grid;
    grid-template-columns: 50px 90%;
    grid-gap: 10px;
    align-items: center;
    justify-self: right;
    color: #707070;
    font-size: 16px;
    font-weight: 500;
}

.burger {
    display: none;
}

.modalMenu {
    width: 100%;
    display: grid;
    grid-gap: 15px;
    justify-items: center;
    align-items: center;
    padding: 30px 0;
}

.modalMenu a span {
    color: #fff;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 20px;
    text-transform: uppercase;
}
.modalMenu a:hover span {
    color: #6e0f0f;
}

.phonesMenu {
    grid-area: phonesMenu;
    display: none;
}
.searchMenu {
    grid-area: searchMenu;
    display: none;
}

.cartMenu {
    grid-area: cartMenu;
    display: grid;
    grid-template-columns: 50px 90%;
    grid-gap: 10px;
    align-items: center;
    justify-self: right;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 1100;
    cursor: pointer;
}
.cartMenuActive {
    grid-area: cartMenu;
    display: grid;
    grid-template-columns: 50px 90%;
    grid-gap: 10px;
    align-items: center;
    justify-self: right;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 1100;
    cursor: pointer;
    color: #9D0917;
    fill: #9D0917
}

.productMiniCart {
    width: 260px;
    display: grid;
    grid-template-columns: 65px 120px 30px;
    grid-template-rows: auto;
    grid-gap: 10px;
    padding: 10px;
    align-self: start;
    align-items: center;
}

.otherInCart {
    justify-self: end;
    padding-right: 40px;
    font-style: italic;
}

.productMiniCart img {
    max-width: 65px;
    max-height: 100px;
}
.productMiniCart p {
    font-size: 12px;
}


@media (min-width: 1101px) {
    .burger {
        display: none;
    }
    .menuGrid {
        display: grid;
    }
}

@media (max-width: 900px) {
    .wrapper {
        grid-template-columns: 10% 1fr auto auto 10%;
    }
    .burger {
        display: block;
    }
    .menuGrid {
        display: none;
    }
}

@media (max-width: 600px) {
    .userMenu {
        justify-self: center;
    }
    .generalMenu {
        justify-self: center;
    }
    .menuItemTitle {
        display: none;
    }
    .phonesMenu {
        grid-area: phonesMenu;
        display: block;
    }
    .phonesMenuActive {
        grid-area: phonesMenu;
        display: block;
        color: #9D0917;
        fill: #9D0917;
    }
    .searchMenu {
        grid-area: searchMenu;
        display: block;
    }
    .searchMenuActive {
        grid-area: searchMenu;
        display: block;
        color: #9D0917;
        fill: #9D0917
    }

    .wrapper {
        grid-template-areas:
                "generalMenu phonesMenu searchMenu cartMenu userMenu"
                "phoneMenu1 phoneMenu1 phoneMenu1 phoneMenu1 phoneMenu1";
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 60px 1fr;
        grid-gap: 0;
        align-items: center;
        justify-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
}




