@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row p strong img {
  width: 300px;
}

.row a,
.row a:visited {
  color: #d90015;
}

.row a:hover {
  color: #9d0917;
}

.MuiDrawer-paperAnchorTop {
  background-color: unset!important;
}

.rec-carousel-wrapper .rec-carousel .rec-arrow-left {
  background-color: rgba(161,161,161,0.3);
  border-radius: 0;
  margin-right: -20px;
  width: 20px;
  min-width: 20px;
  height: 60px;
  z-index: 100;
  margin-left: -10px;
  border: 0;
}
.rec-carousel-wrapper .rec-carousel .rec-arrow-right {
  background-color: rgba(161,161,161,0.3);
  border-radius: 0;
  margin-left: -20px;
  width: 20px;
  min-width: 20px;
  height: 60px;
  z-index: 100;
  margin-right: -10px;
  border: 0;
}

.rec-carousel-wrapper .rec-carousel .rec-arrow-left:hover:enabled,
.rec-carousel-wrapper .rec-carousel .rec-arrow-right:hover:enabled {
  background-color: rgba(217,0,21,.9);
}

.gEIJqc:hover:enabled,
.gEIJqc:focus:enabled,
.rec-carousel-wrapper .rec-dot_active:focus {
  color: #fff;
  background-color: rgba(161,161,161,1);
  box-shadow: 0 0 2px 0 #333;
}

.rec-carousel-wrapper .rec-dot_active,
.rec-carousel-wrapper .rec-dot_active:hover {
  background-color: rgba(217,0,21,.5);
  box-shadow: 0 0 1px 3px rgba(217,0,21,1);
}

html,
body,
* {
  padding: 0;
  margin: 0;
}

.adminWrapper {
  font-family: Roboto;
  overflow-x: hidden;
  background: #3c3e41;
  color: #fff;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-areas:
          "leftPanel leftBlock header"
          "leftPanel leftBlock main";
  grid-template-columns: 80px 260px 1fr;
  grid-template-rows: 80px 1fr;
  grid-gap: 1px;
}

header {
  grid-area: header;
  background: #04070F;
  border-bottom: 1px solid #4D5054;
}

menu {
  grid-area: menu;
}

menu ul li {
  display: inline;
  list-style-position: inside;
  list-style-type: none;
}

.leftPanel {
  grid-area: leftPanel;
  background: #04070F;
  border-right: 1px solid #4D5054;
}

.leftBlock {
  grid-area: leftBlock;
  display: grid;
  grid-template-areas:
          "leftBlockAvatarZone"
          "leftBlockMenu";
  grid-template-columns: 260px;
  grid-template-rows: 150px 1fr;
}

main {
  grid-area: main;
  background: rgb(4,18,29);
  background: linear-gradient(180deg, rgba(4,18,29,0.8) 0%, rgba(0,96,176,0.8) 66%, rgba(0,168,242,0.8) 100%);
  padding: 3% 10%;
}

.mainBlock {
  height: 100%;
  background: #282D3F;
  border: 1px solid #69748F;
  box-shadow: 15px 15px 25px rgba(0,0,0,0.4);
  display: grid;
  grid-template-areas:
          "mainBlockHeader"
          "mainBlockContent"
          "mainBlockFooter";
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 0px;
  grid-gap: 20px;
}

.mainBlockHeader {
  grid-area: mainBlockHeader;
  display: grid;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: center;
  background: rgba(0,142,206,0.45);
  font-size: 16px;
}

.mainBlockContent {
  grid-area: mainBlockContent;
  display: grid;
  grid-template-areas:
          "mainBlockLeft mainBlockRight";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.mainBlockLeft {
  grid-area: mainBlockLeft;
  border-right: 1px solid #535561;
  padding: 0 30px;
  display: grid;
  grid-template-areas:
          "buttonSwitch mainBlockRight"
          "buttonSwitch mainBlockRight";
  grid-template-columns: 40px 1fr;
  grid-template-rows: 120px 1fr;
}

.buttonSwitch {
  grid-area: buttonSwitch;
  width: 40px;
  height: 100%;
  background: rgba(0,142,206,0.45);
  margin-left: -50px;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.3);
}
.buttonSwitch:hover {
  background: rgba(9,35,41,0.70);
}

.buttonSwitch:hover svg {
  -webkit-animation-name: rotation;
          animation-name: rotation;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.buttonSwitch:active {
  background: rgba(9,35,41,0.9);
  box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
  100% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
  100% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
}

.searchBlock {
  grid-area: searchBlock;
}

.demandsBlock {
  grid-area: demandsBlock;
}

.mainBlockRight {
  grid-area: mainBlockRight;
  padding: 0 30px;
}

.mainBlockFooter {
  grid-area: mainBlockFooter;
  justify-self: center;
  align-self: center;
}

.mainBlockButton {
  background: #D52945;
  border: 1px solid #D52945;
  border-radius: 30px;
  width: 190px;
  height: 50px;
  color: white;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  outline: none;
  outline-offset: 0;
}

.mainBlockButton:hover {
  background: #801F2F;
}

footer {
  grid-area: footer;
  background: #000;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

@media (max-width: 1780px) {
  main {
    padding: 3% 5%;
  }
}

@media (max-width: 1620px) {
  main {
    padding: 3% 3%;
  }
}

@media (max-width: 1560px) {
  main {
    padding: 3% 3%;
  }
  .mainBlockContent {
    grid-template-columns: 0.8fr 1.2fr;
  }
}

@media (max-width: 1440px) {
  .mainBlockContent {
    grid-template-columns: 0.6fr 1fr;
  }
}

.front_wrapper__3tATy {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    background: #EFF3F6;
    color: #000;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-areas:
            "header"
            "mainWrapper"
            "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 160px 1fr auto;
    grid-gap: 50px;
}

.front_header__2DP9S {
    grid-area: header;
}

.front_footer__2Sfp7 {
    grid-area: footer;
}

.front_mainWrapper__iangT {
    grid-area: mainWrapper;
    display: grid;
    grid-template-areas:
                ". main1 .";
    grid-template-columns: 10% 1fr 10%;
}

.front_main__1kmn9 {
    grid-area: main1;
}

@media (max-width: 1600px) {
    .front_mainWrapper__iangT {
        grid-template-columns: 5% 1fr 5%;
    }
}

@media (max-width: 1250px) {
    .front_mainWrapper__iangT {
        grid-template-columns: 3% 1fr 3%;
    }
}

@media (max-width: 500px) {
    .front_mainWrapper__iangT {
        grid-template-columns: 1% 1fr 1%;
        grid-gap: 10px;
    }
}

.footer_wrapper__2WDM1 {
    width: 100%;
    background: #1C1C1C;
    color: #fff;
    display: grid;
    grid-template-areas:
            ". footerColumn1 footerColumn2 footerColumn3 footerColumn4 .";
    grid-template-columns: 10% 1fr 1fr 1fr 1fr 10%;
    grid-template-rows: 120px;
    grid-gap: 10px;
    align-items: center;
    padding: 20px 0;
}

.footer_wrapper__2WDM1 span {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
}

.footer_footerColumn1__3_CAY {
    grid-area: footerColumn1;
}
.footer_footerColumn2__30bjY {
    grid-area: footerColumn2;
}
.footer_footerColumn3__3IWcs {
    grid-area: footerColumn3;
}
.footer_footerColumn4__1wckf {
    grid-area: footerColumn4;
}

.footer_footerColumn2__30bjY a:hover,
.footer_footerColumn2__30bjY a:active {
    text-decoration: underline;
}
.footer_footerColumn3__3IWcs a:hover,
.footer_footerColumn3__3IWcs a:active {
    text-decoration: underline;
}

@media (max-width: 1250px) {
    .footer_wrapper__2WDM1 {
        grid-template-areas:
                ". footerColumn2 footerColumn1 ."
                ". footerColumn3 footerColumn4 .";
        grid-template-columns: 10% 1fr 1fr 10%;
        grid-template-rows: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .footer_wrapper__2WDM1 {
        grid-template-areas:
                ". footerColumn2 ."
                ". footerColumn3 ."
                ". footerColumn1 ."
                ". footerColumn4 .";
        grid-template-columns: 10% 1fr 10%;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        justify-items: center;
    }
}

.header_wrapper__3xJLO {
    width: 100%;
    display: grid;
    grid-template-areas:
            "topMenu"
            "topBar";
    grid-template-columns: 1fr;
    grid-template-rows: 60px 100px;
    grid-gap: 0;
}

.header_topMenu__3StOa {
    grid-area: topMenu;
}

.header_topBar__owrfn {
    grid-area: topBar;
}

.topMenu_wrapper__3GdJT {
    width: 100%;
    display: grid;
    background: #1C1C1C;
    color: white;
    grid-template-areas:
            ". generalMenu cartMenu userMenu .";
    grid-template-columns: 10% 1fr auto auto 10%;
    grid-template-rows: 60px;
    grid-gap: 20px;
    align-items: center;
}

.topMenu_generalMenu__yOet1 {
    grid-area: generalMenu;
    justify-self: left;
}

.topMenu_menuGrid__Uwp4O {
    display: grid;
    grid-template-columns: 80px 120px 160px 90px 90px auto;
    grid-template-rows: 60px;
    grid-gap: 0;
}

.topMenu_menuGrid__Uwp4O span {
    height: 57px;
    display: grid;
    align-items: center;
    text-align: center;
    border-bottom: 3px solid #1C1C1C;
    font-size: 14px;
    font-weight: 500;
}

.topMenu_menuGrid__Uwp4O span:hover {
    cursor: pointer;
    background-color: #333;
    border-bottom: 3px solid #D90015;
    transition: margin-bottom 0.5s, background-color 1s;
}

.topMenu_menuGrid__Uwp4O span a {

}

.topMenu_userMenu__keBSQ {
    grid-area: userMenu;
    display: grid;
    grid-template-columns: 50px 90%;
    grid-gap: 10px;
    align-items: center;
    justify-self: right;
    color: #707070;
    font-size: 16px;
    font-weight: 500;
}

.topMenu_burger__25Bu- {
    display: none;
}

.topMenu_modalMenu__20Sh9 {
    width: 100%;
    display: grid;
    grid-gap: 15px;
    justify-items: center;
    align-items: center;
    padding: 30px 0;
}

.topMenu_modalMenu__20Sh9 a span {
    color: #fff;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 20px;
    text-transform: uppercase;
}
.topMenu_modalMenu__20Sh9 a:hover span {
    color: #6e0f0f;
}

.topMenu_phonesMenu__28J0Y {
    grid-area: phonesMenu;
    display: none;
}
.topMenu_searchMenu__35BJQ {
    grid-area: searchMenu;
    display: none;
}

.topMenu_cartMenu__DvGI4 {
    grid-area: cartMenu;
    display: grid;
    grid-template-columns: 50px 90%;
    grid-gap: 10px;
    align-items: center;
    justify-self: right;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 1100;
    cursor: pointer;
}
.topMenu_cartMenuActive__2XxFR {
    grid-area: cartMenu;
    display: grid;
    grid-template-columns: 50px 90%;
    grid-gap: 10px;
    align-items: center;
    justify-self: right;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 1100;
    cursor: pointer;
    color: #9D0917;
    fill: #9D0917
}

.topMenu_productMiniCart__1z0XJ {
    width: 260px;
    display: grid;
    grid-template-columns: 65px 120px 30px;
    grid-template-rows: auto;
    grid-gap: 10px;
    padding: 10px;
    align-self: start;
    align-items: center;
}

.topMenu_otherInCart__2KGE_ {
    justify-self: end;
    padding-right: 40px;
    font-style: italic;
}

.topMenu_productMiniCart__1z0XJ img {
    max-width: 65px;
    max-height: 100px;
}
.topMenu_productMiniCart__1z0XJ p {
    font-size: 12px;
}


@media (min-width: 1101px) {
    .topMenu_burger__25Bu- {
        display: none;
    }
    .topMenu_menuGrid__Uwp4O {
        display: grid;
    }
}

@media (max-width: 900px) {
    .topMenu_wrapper__3GdJT {
        grid-template-columns: 10% 1fr auto auto 10%;
    }
    .topMenu_burger__25Bu- {
        display: block;
    }
    .topMenu_menuGrid__Uwp4O {
        display: none;
    }
}

@media (max-width: 600px) {
    .topMenu_userMenu__keBSQ {
        justify-self: center;
    }
    .topMenu_generalMenu__yOet1 {
        justify-self: center;
    }
    .topMenu_menuItemTitle__GSfh8 {
        display: none;
    }
    .topMenu_phonesMenu__28J0Y {
        grid-area: phonesMenu;
        display: block;
    }
    .topMenu_phonesMenuActive__2EAQp {
        grid-area: phonesMenu;
        display: block;
        color: #9D0917;
        fill: #9D0917;
    }
    .topMenu_searchMenu__35BJQ {
        grid-area: searchMenu;
        display: block;
    }
    .topMenu_searchMenuActive__1j7aq {
        grid-area: searchMenu;
        display: block;
        color: #9D0917;
        fill: #9D0917
    }

    .topMenu_wrapper__3GdJT {
        grid-template-areas:
                "generalMenu phonesMenu searchMenu cartMenu userMenu"
                "phoneMenu1 phoneMenu1 phoneMenu1 phoneMenu1 phoneMenu1";
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 60px 1fr;
        grid-gap: 0;
        align-items: center;
        justify-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
}





.phoneMenu {
    grid-area: phoneMenu1;
    width: 100%;
    height: auto;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    display: grid;
    justify-items: center;
    z-index: 999;
}

.phoneMenu span {
    padding: 20px;
}

.phoneMenu-enter {
    opacity: 1;
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    width: 100%;
    position: fixed;
    top: -2000px;
    left: 0;
}
.phoneMenu-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    top: 60px;
    transition: opacity 1300ms, width 300ms, top 300ms, -webkit-transform 300ms;
    transition: opacity 1300ms, transform 300ms, width 300ms, top 300ms;
    transition: opacity 1300ms, transform 300ms, width 300ms, top 300ms, -webkit-transform 300ms;
}
.phoneMenu-exit {
    opacity: 1;
}
.phoneMenu-exit-active {
    opacity: 0;
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}


.cartMenu {
    grid-area: phoneMenu1;
    width: 260px;
    height: 300px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255,255,255,0.95);
    color: black;
    display: grid;
    grid-template-rows: 40px auto 20px 40px;
    align-items: center;
    justify-items: center;
    z-index: 999;
    border: 1px solid #1C1C1C;
}

.cartMenu span {
    padding-top: 20px;
}

.cartMenu a,
.cartMenu a:visited {
    color: #9D0917;
}

.cartMenu a:hover,
.cartMenu a:active {
    color: #6e0f0f;
    text-decoration: underline;
}

.cartMenu-enter {
    opacity: 1;
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
}
.cartMenu-enter div {
    opacity: 0;
}
.cartMenu-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    top: 0;
    height: 300px;
    width: 260px;
    transition: opacity 500ms, width 200ms, height 1000ms, -webkit-transform 300ms;
    transition: opacity 500ms, transform 300ms, width 200ms, height 1000ms;
    transition: opacity 500ms, transform 300ms, width 200ms, height 1000ms, -webkit-transform 300ms;
}
.cartMenu-enter-active div {
    opacity: 0;
    transition: opacity 1000ms;
}
.cartMenu-exit {
    height: 300px;
    width: 260px;
    opacity: 1;
}
.cartMenu-exit div {
    opacity: 1;
}
.cartMenu-exit-active {
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 300ms, width 300ms, height 300ms;
}
.cartMenu-exit-active div {
    opacity: 0;
    transition: opacity 100ms;
}

.topBar_wrapper__ZmL7I {
    width: 100%;
    display: grid;
    background: #fff;
    color: #1C1C1C;
    grid-template-areas:
            ". logo search phones .";
    grid-template-columns: 10% 150px 2fr 1fr 10%;
    grid-template-rows: 100px;
    grid-gap: 20px;
    align-items: center;
}

.topBar_logo__2so2p {
    grid-area: logo;
}

.topBar_search__9FRYd {
    grid-area: search;
    padding: 0 30px;
}

.topBar_search__9FRYd input {

}

.topBar_phones__1lQXq {
    grid-area: phones;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.topBar_phones__1lQXq a,
.topBar_phones__1lQXq a:visited {
    color: #04070F;
}

.topBar_phones__1lQXq a:hover,
.topBar_phones__1lQXq a:active {
    color: #9D0917;
    text-decoration: underline;
}


@media (max-width: 1450px) {
    .topBar_wrapper__ZmL7I {
        grid-template-columns: 10% 150px 1.5fr 1fr 10%;
    }
}

@media (max-width: 1250px) {
    .topBar_wrapper__ZmL7I {
        grid-template-columns: 10% 150px 2fr 1fr 10%;
    }
    .topBar_phones__1lQXq {
        grid-gap: 5px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}



@media (max-width: 900px) {
    .topBar_wrapper__ZmL7I {
        grid-template-areas:
                ". logo search .";
        grid-template-columns: 4% 150px 1fr 4%;
    }
    .topBar_phones__1lQXq {
        display: none;
    }
}

@media (max-width: 600px) {
    .topBar_wrapper__ZmL7I {
        grid-template-areas:
                "logo";
        grid-template-columns: 1fr;
        align-self: center;
        justify-self: center;
        text-align: center;
        margin-top: 160px;
    }
    .topBar_search__9FRYd {
        display: none;
    }
}

.catalog_srez__3-3ix {
    border-bottom: 1px dashed #4D5054;
    width: 3000%;
    position: relative;
    bottom: 20px;
    overflow: hidden;
}

.catalog_expand__3X9P- {
    border-radius: 0 0 6px 6px;
    /* display: flex; */
    width: 130px;
    /* justify-self: right; */
    right: 10px;
    bottom: -1px;
    padding: 3px 3px 3px 3px;
    font-size: 12px;
    font-family: 'Roboto';
    color: #707070;
    background: #EFF3F6;
    justify-self: end;
    text-align: center;
    position: relative;
    z-index: 50;
}

.catalog_expand__3X9P-:hover {
    color: #9D0917;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0,0,0,0.4);
}

.catalog_hidden__3cPl_ {
    display: none;
    transition: bottom 1s;
}

.catalog_wrapper__1xPVi {
    width: 100%;
    display: grid;
    grid-template-areas:
            "gridCards";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
}

.catalog_wrapperMin__2ED3q {
    width: 100%;
    display: grid;
    grid-template-areas:
            "gridCards";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px;
    justify-items: center;
}

.catalog_title__1qjt2 {
    grid-area: title;
    display: none;
}

.catalog_gridCards__1VIBQ {
    grid-area: gridCards;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    justify-items: center;
    transition: height 1s cubic-bezier(1, 1, 0, 0) 500ms;
}

.catalog_wrapperMin__2ED3q .catalog_gridCards__1VIBQ {
    grid-area: gridCards;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    justify-items: center;
    position: relative;
}

@media (max-width: 1350px) {
    .catalog_gridCards__1VIBQ {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 900px) {
    .catalog_gridCards__1VIBQ {
        grid-template-columns: 1fr;
    }
    .catalog_wrapperMin__2ED3q .catalog_gridCards__1VIBQ {
        grid-gap: 5px;
    }
}

@media (max-width: 500px) {
    .catalog_gridCards__1VIBQ {
        grid-gap: 15px;
    }
    .catalog_wrapperMin__2ED3q .catalog_gridCards__1VIBQ {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

.card_wrapper__2s_I8 {
    width: 100%;
    background: #fff;
    color: #000;
    border-radius: 3px;
}

.card_wrapper__2s_I8:hover {
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}

.card_wrapperMin__2Q7s3 {
    width: 100%;
    background: #fff;
    color: #000;
    border-radius: 3px;
    position: relative;
}

.card_wrapperMin__2Q7s3 .card_cardList__37uU3 {
    display: none;
    transition: display 1s;
}

.card_wrapperMin__2Q7s3 .card_cardTitle__3DIpX {
    display: none;
}


.card_cardContent__K7a5A {
    display: grid;
    grid-template-areas:
            "cardTitle cardTitle"
            "cardImage cardList";
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 30px 1fr;
    grid-gap: 20px;
    align-items: center;
    padding: 20px;
}

.card_wrapperMin__2Q7s3 .card_cardContent__K7a5A {
    grid-template-areas:
            "cardImage";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    padding: 10px;
    transition: width 0.3s cubic-bezier(1, 1, 1, 1) 100ms;
}

.card_wrapperMin__2Q7s3 .card_cardContent__K7a5A:hover {
    background: #fff;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
    min-width: 100%;
    width: auto;
    grid-template-areas:
            "cardImage"
            "cardList";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    justify-items: center;
    position: absolute;
    z-index: 100;
}

.card_wrapperMin__2Q7s3 .card_cardContent__K7a5A:hover .card_cardList__37uU3 {
    display: block;
    justify-self: center;
}


.card_cardTitle__3DIpX {
    width: 100%;
    grid-area: cardTitle;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.card_cardImage__YIJLj {
    grid-area: cardImage;
    width: 100px;
    justify-self: center;
}

.card_wrapperMin__2Q7s3 .card_cardImage__YIJLj {
    width: 80px;
}


.card_cardList__37uU3 {
    grid-area: cardList;
}

.card_wrapperMin__2Q7s3 .card_cardList__37uU3 {
    grid-area: cardList;
    align-self: center;
    text-align: center;
}

.card_cardList__37uU3 a,
.card_cardList__37uU3 a:visited {
    color: #04070F;
}

.card_cardList__37uU3 a:hover {
    text-decoration: underline;
    color: black;
}

.card_cardList__37uU3 li {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    cursor: pointer;
}

.card_cardList__37uU3 li:hover {
    text-decoration: underline;
    color: black;
}

@media (max-width: 950px) {
    .card_wrapperMin__2Q7s3 .card_cardImage__YIJLj {
        width: 60px;
    }
}

@media (max-width: 900px) {
    .card_cardImage__YIJLj {
        width: 200px;
    }
    .card_wrapperMin__2Q7s3 .card_cardImage__YIJLj {
        width: 60px;
    }
}

@media (max-width: 600px) {
    .card_cardImage__YIJLj {
        width: 140px;
    }
    .card_wrapperMin__2Q7s3 .card_cardImage__YIJLj {
        width: 50px;
    }
}

@media (max-width: 500px) {
    .card_cardImage__YIJLj {
        width: 100px;
    }
    .card_wrapperMin__2Q7s3 .card_cardImage__YIJLj {
        width: 40px;
    }
}

.productGrid_wrapper__1oERH {
    width: 100%;
    display: grid;
    grid-template-areas:
            "gridProductCards"
            "paginator";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 20px;
    grid-gap: 0;
    justify-items: center;
}

.productGrid_productLoader__2AI9Z {
    grid-area: productLoader;
}

.productGrid_title__3IYuf {
    grid-area: title;
}

.productGrid_breadcrumbs__lXxok {
    grid-area: breadcrumbs;
    justify-self: start;
    align-self: center;
}

.productGrid_breadcrumbs__lXxok a,
.productGrid_breadcrumbs__lXxok a:visited,
.productGrid_breadcrumbs__lXxok a:active {
    color: rgba(0, 0, 0, 0.54);
}

.productGrid_breadcrumbs__lXxok a:hover {
    color: rgba(217,0,21,.9);
}

.productGrid_gridProductCards__1XiOz {
    grid-area: gridProductCards;
    /*background: #fff;*/
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    justify-items: center;
    border-radius: 4px;
}

.productGrid_gridMobileCards__QMZUq {
    grid-area: gridProductCards;
    display: none;
    width: 100%;
}

.productGrid_paginator__2TcPj {
    grid-area: paginator;
    padding-top: 20px;
}

.productGrid_notFound__23mXX {
    align-self: center;
    justify-self: center;
    font-weight: 600;
}

@media (max-width: 1250px) {
    .productGrid_gridProductCards__1XiOz {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 900px) {
    .productGrid_gridProductCards__1XiOz {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .productGrid_gridProductCards__1XiOz {
        grid-template-columns: 1fr;
        grid-gap: 15px;
        display: none;
    }
    .productGrid_gridMobileCards__QMZUq {
        grid-area: gridProductCards;
        display: block;
    }
}

.card_wrapper__9AQiw {
    width: 100%;
    background: #fff;
    color: #1C1C1C;
    border-radius: 3px;
}

.card_wrapper2__3oVNj {
    -webkit-filter: opacity(0.6);
            filter: opacity(0.6);
}

.card_wrapper__9AQiw:hover {
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}

.card_wrapper__9AQiw a,
.card_wrapper__9AQiw a:hover,
.card_wrapper__9AQiw a:visited {
    color: #04070F;
}

.card_cardContent__3rYbR {
    display: grid;
    grid-template-areas:
            "cardTitle"
            "cardArt"
            "cardImage"
            "cardPrice"
            "cardDate"
            "cardButton"
            "cardButtonWhatsapp"
            "cardDescription";
    grid-template-columns: 1fr;
    grid-template-rows: 10px 8px 200px 10px 10px;
    grid-gap: 15px;
    align-items: center;
    padding: 20px;
}

.card_cardTitle__3Mt0L {
    grid-area: cardTitle;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.card_cardArt__QLHrr {
    grid-area: cardArt;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    text-overflow:ellipsis;
}

.card_cardImage__1Phty {
    grid-area: cardImage;
    display: grid;
    width: 100%;
    align-items: center;
    justify-items: center;
}
.card_cardImage__1Phty img {
    max-width: 100%;
    max-height: 200px;
}

.card_cardPrice__1-TL_ {
    grid-area: cardPrice;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

.card_cardDate__pQa4M {
    grid-area: cardDate;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    font-family: 'Roboto';
    color: #707070;
}

.card_cardButton__3kHi4 {
    grid-area: cardButton;
    justify-self: center;
}

.card_cardButton__3kHi4 .card_buttonOff__1mgbh {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #4D5054;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.card_cardButton__3kHi4 button {
    grid-area: cardButton;
    width: 160px;
    height: 40px;
    background: #D90015;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.card_cardButton__3kHi4 button:focus {
    border: none;
    outline: none;
}

.card_cardButton__3kHi4 button:hover {
    background: #9D0917;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}
.card_cardButton__3kHi4 button:active {
    background: #9D0917;
    box-shadow: 0 0px 5px rgba(0,0,0,0.6);
    transition: background 0.4s, box-shadow 1s;
    border: 0;
    outline: none;
}

.card_cardButtonWhatsapp__3E94v {
    grid-area: cardButtonWhatsapp;
    display: flex;
    justify-items: center;
    justify-content: center;
    justify-self: center;
    align-items: center;
    font-weight: 600;
}

.card_cardButtonWhatsapp__3E94v svg {
    fill: green;
}

.card_cardDescription__1xgEy {
    grid-area: cardDescription;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #707070;
    height: 40px;
}



.FiltersBlock_srez__fwKFy {
    border-bottom: 1px dashed #4D5054;
    width: 2000%;
    position: relative;
    bottom: 21px;
    left: -5000px;
    overflow: hidden;
}

.FiltersBlock_expand__3vmp5 {
    bottom: 10px;
    border: 0 solid #B4B4B4;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    /* display: flex; */
    width: 130px;
    /* justify-self: right; */
    right: 0;
    left: 100%;
    padding: 3px 3px 3px 3px;
    font-size: 12px;
    font-family: 'Roboto';
    color: #707070;
    background: white;
    justify-self: end;
    margin-right: 150px;
    text-align: center;
}

.FiltersBlock_expand__3vmp5:hover {
    color: #9D0917;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0,0,0,0.4);
}

.FiltersBlock_wrapper__3_ik5 {
    width: 100%;
    position: relative;
    bottom: 20px;
    display: grid;
}

.FiltersBlock_filtersBlock__3qFgU {
    background-color: #ffffff;
    display: grid;
    grid-template-areas:
            "filtersLabel sort"
            "activeFilters sort";
    grid-template-columns: 1fr auto;
    grid-template-rows: 10px 1fr;
    grid-gap: 40px;
    justify-items: start;
    padding: 20px;
}

.FiltersBlock_filtersBlockHide__1sFxK {
    display: none;
}

.FiltersBlock_filtersLabel__3NuOt {
    grid-area: filtersLabel;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    align-items: center;
}

.FiltersBlock_activeFilters__2K1bB {
    grid-area: activeFilters;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
}

.FiltersBlock_sort__2GQvi {
    grid-area: sort;
    width: 100%;
    align-self: end;
    justify-self: end;
    text-align: right;
}

.FiltersBlock_activeFilters__2K1bB span {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    align-items: center;
}
.FiltersBlock_activeFilters__2K1bB span span {
    flex: 1 1 1;
    margin: 3px 3px;
}

.FiltersBlock_vendorsBlock__2A4CG {
    min-width: 200px;
    height: auto;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    padding: 20px;
    position: relative;
}

.FiltersBlock_vendorsTitle__3QUQJ {
    font-family: 'Roboto';
    font-size: 12px;
    text-transform: uppercase;
    background: white;
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 5px;
}

.FiltersBlock_filterReload__J0GqX {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-size: 10px;
    text-transform: uppercase;
    background: white;
    position: absolute;
    top: -12px;
    right: 20px;
    padding: 0 5px;
    color: #9D0917;
    cursor: pointer;
}

@media (max-width: 700px) {
    .FiltersBlock_filtersBlock__3qFgU {
        grid-template-areas:
                "filtersLabel"
                "activeFilters"
                "sort";
        grid-template-columns: 1fr;
        grid-template-rows: 20px 1fr 120px;
    }
    .FiltersBlock_sort__2GQvi {
        justify-self: center;
        text-align: center;
    }
}

.dialogModal_wrapper__3k8iw {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: auto auto auto;
    overflow-y: visible!important;
}

.dialogModal_items__3-bBF {

}

@media (max-width: 500px) {
    .dialogModal_wrapper__3k8iw {
        grid-template-columns: auto auto;
    }
}

.productPage_wrapper___OOfF {
    width: 100%;
    display: grid;
    grid-template-areas:
            "productContent";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
}

.productPage_productContent__qWavd {
    grid-area: productContent;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    display: grid;
    grid-template-areas:
            ". productTitle productTitle ."
            ". productImagesBlock productActionBlock ."
            ". productInfoBlock productInfoBlock .";
    grid-template-columns: 5% 45% 1fr 5%;
    grid-template-rows: 30px 1fr 1fr;
    grid-gap: 30px;
    padding: 50px 0;
}

.productPage_title__2oDKB {
    grid-area: productTitle;
    font-size: 22px;
    font-weight: 700;
}

.productPage_productImagesBlock__jUP3b {
    grid-area: productImagesBlock;
    width: 100%;
}

.productPage_productImagesBlock__jUP3b img {
    max-width: 100%;
}

.productPage_productActionBlock__1p5xS {
    grid-area: productActionBlock;
    width: 100%;
    display: grid;
    grid-template-areas:
            "price availability"
            "count count"
            "code code"
            "art art"
            "button button"
            "quick quick"
            "description description";
    grid-template-columns: 70% 30%;
    grid-template-rows: 40px 50px 20px 10px 60px 20px 1fr;
    grid-gap: 20px;
    align-items: center;
}

.productPage_quick__3SC6I {
    grid-area: quick;
}

.productPage_cardButtonWhatsapp__1VEal {
    grid-area: cardButtonWhatsapp;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.productPage_cardButtonWhatsapp__1VEal svg {
    fill: green;
}

.productPage_wrapper___OOfF a,
.productPage_wrapper___OOfF a:hover,
.productPage_wrapper___OOfF a:visited {
    color: #04070F;
}

.productPage_productInfoBlock__7znM_ {
    grid-area: productInfoBlock;
}

.productPage_price__3MGe- {
    grid-area: price;
    font-size: 30px;
    font-weight: 700;
    align-self: end;
}
.productPage_availability__3KSWW {
    grid-area: availability;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto';
    align-self: end;
    justify-self: end;
}
.productPage_count__2vzkf {
    grid-area: count;
    height: 50px;
    border-top: 2px solid #04070F;
    border-bottom: 2px solid #04070F;
    font-size: 14px;
    font-weight: 600;
    display: grid;
    align-items: center;
}
.productPage_code__VCYm1 {
    grid-area: code;
    font-size: 14px;
    font-weight: 600;
}

.productPage_art__89hF0 {
    grid-area: art;
    font-size: 14px;
    font-weight: 600;
}

.productPage_buttonOff__3Wn3n {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #4D5054;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.productPage_button__1NUvq {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #D90015;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.productPage_button__1NUvq:focus {
    border: none;
    outline: none;
}

.productPage_button__1NUvq:hover,
.productPage_buttonOff__3Wn3n:hover {
    background: #9D0917;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}
.productPage_button__1NUvq:active {
    background: #9D0917;
    box-shadow: 0 0 5px rgba(0,0,0,0.6);
    transition: background 0.4s, box-shadow 1s;
    border: 0;
    outline: none;
}


.productPage_description__3Q15z {
    grid-area: description;
    align-self: start;
    font-size: 14px;
    font-weight: 600;
}


@media (max-width: 1050px) {
    .productPage_productContent__qWavd {
        grid-template-areas:
                ". productTitle productTitle ."
                ". productImagesBlock productImagesBlock ."
                ". productActionBlock productActionBlock ."
                ". productInfoBlock productInfoBlock .";
        grid-template-columns: 5% 45% 1fr 5%;
        grid-template-rows: 30px 1fr 0.5fr 1fr;
    }
}

@media (max-width: 500px) {
    .productPage_productContent__qWavd {
        grid-template-rows: 50px 1fr 0.5fr 1fr;;
    }
}

.imageBlock_imageBlock__3Gqm1 {
    display: block;
}

.imageBlock_imageMobileBlock__1zooW {
    display: none;
}

.imageBlock_imageMobileBlock__1zooW img {
    align-self: center;
    vertical-align: center;
    max-height: 300px;
}

@media (max-width: 600px) {
    .imageBlock_imageBlock__3Gqm1 {
        display: none!important;
    }

    .imageBlock_imageMobileBlock__1zooW {
        display: block;
    }
}

.opt_wrapper__1WhDp {

}

.opt_wrapper__1WhDp h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 30px;
}

.opt_wrapper__1WhDp h2 {
    font-size: 1.8em;
    text-align: center;
    margin: 30px 0;
}

.opt_wrapper__1WhDp p {
    font-size: 1em;
    margin: 10px;
}

.opt_center__1AfVN {
    display: grid;
    justify-items: center;
    text-align: center;
}

.opt_phonesBlock__2zJEn p {
    text-align: center;
    display: flex;
    justify-content: center;
}

.opt_categoryList__2UsHQ {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 20px;
}

.opt_categoryItem__24gSu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 20px;
    grid-gap: 10px;
    align-items: center;
    justify-items: center;
}

.opt_wrapper__1WhDp li {
    list-style: none;
}

.opt_wrapper__1WhDp a,
.opt_wrapper__1WhDp a:visited {
    color: #6e0f0f;
}
.opt_wrapper__1WhDp a:hover,
.opt_wrapper__1WhDp a:active {
    color: #9D0917;
    text-decoration: underline;
}

.opt_wrapper__1WhDp .opt_svg__1TwaE {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    vertical-align: center;
}

.opt_button__2WGU4 {
    width: 300px;
    border-radius: 30px;
    background-color: #9D0917;
    border: 1px solid #B4B4B4;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.opt_button__2WGU4:hover {
    background-color: #6e0f0f;
}

.opt_buttonGroupFirst__3kXtf {
    border-radius: 30px 0 0 30px;
}

.opt_buttonGroupSecond__1anwQ {
    width: 60px;
    background-color: green;
    border: 1px solid #B4B4B4;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 0 30px 30px 0;
}

.opt_buttonGroupSecond__1anwQ:hover {
    background-color: darkgreen;
}

a.opt_buttonGroupSecond__1anwQ,
a:visited.opt_buttonGroupSecond__1anwQ,
a:hover.opt_buttonGroupSecond__1anwQ  {
    color: white;
}

.opt_buttonWhatsapp__2cCGx {
    display: grid;
    grid-template-columns: 300px 60px;
}


@media (max-width: 600px) {
    .opt_categoryList__2UsHQ {
        grid-template-columns: 1fr;
    }
}

.cart_wrapper__NiDzA {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.cart_paper__2TeD9 {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.cart_productBlock__HAWAh {
    display: grid;
    grid-template-columns: 200px auto 200px 50px;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}

.cart_productBlock__HAWAh a,
.cart_productBlock__HAWAh a:visited {
    color: #04070F;
}

.cart_productBlock__HAWAh a:hover,
.cart_productBlock__HAWAh a:active {
    color: #6e0f0f;
    text-decoration: underline;
}

.cart_productBlock__HAWAh img {
    max-width: 200px;
    max-height: 200px;
}

@media (max-width: 600px) {
    .cart_productBlock__HAWAh {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 20%;
        align-items: start;
    }
}

.orderForm_wrapper__2z2E5 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 60px 1fr;
    grid-gap: 30px;
    align-items: start;
    justify-items: center;
}

.orderForm_wrapper__2z2E5 div {
    width: 100%;
}

/*.wrapper div input,
.wrapper div input:focus {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #B4B4B4;
    padding: 0 10px;
}*/

.orderForm_wrapper__2z2E5 div button {
    width: 100%;
}

.orderForm_button__2mA7P {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #D90015;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.orderForm_button__2mA7P:hover {
    background: #6e0f0f;
}

@media (max-width: 600px) {
    .orderForm_wrapper__2z2E5 {
        grid-template-columns: 1fr;
    }
}

.FormControls_formControl__2ku66.FormControls_error__1t_Zl input,
.FormControls_formControl__2ku66.FormControls_error__1t_Zl textarea {
    border: 3px solid red;
}

.FormControls_formControl__2ku66.FormControls_error__1t_Zl span {
    color: red;
}

.FormControls_formSummaryError__2D6y9 {
    border: 3px solid red;
    padding: 5px;
    color: red;
}


