.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.paper {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.productBlock {
    display: grid;
    grid-template-columns: 200px auto 200px 50px;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}

.productBlock a,
.productBlock a:visited {
    color: #04070F;
}

.productBlock a:hover,
.productBlock a:active {
    color: #6e0f0f;
    text-decoration: underline;
}

.productBlock img {
    max-width: 200px;
    max-height: 200px;
}

@media (max-width: 600px) {
    .productBlock {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 20%;
        align-items: start;
    }
}
