.srez {
    border-bottom: 1px dashed #4D5054;
    width: 3000%;
    position: relative;
    bottom: 20px;
    overflow: hidden;
}

.expand {
    border-radius: 0 0 6px 6px;
    /* display: flex; */
    width: 130px;
    /* justify-self: right; */
    right: 10px;
    bottom: -1px;
    padding: 3px 3px 3px 3px;
    font-size: 12px;
    font-family: 'Roboto';
    color: #707070;
    background: #EFF3F6;
    justify-self: end;
    text-align: center;
    position: relative;
    z-index: 50;
}

.expand:hover {
    color: #9D0917;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0,0,0,0.4);
}

.hidden {
    display: none;
    transition: bottom 1s;
}

.wrapper {
    width: 100%;
    display: grid;
    grid-template-areas:
            "gridCards";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
}

.wrapperMin {
    width: 100%;
    display: grid;
    grid-template-areas:
            "gridCards";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px;
    justify-items: center;
}

.title {
    grid-area: title;
    display: none;
}

.gridCards {
    grid-area: gridCards;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    justify-items: center;
    transition: height 1s cubic-bezier(1, 1, 0, 0) 500ms;
}

.wrapperMin .gridCards {
    grid-area: gridCards;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    justify-items: center;
    position: relative;
}

@media (max-width: 1350px) {
    .gridCards {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 900px) {
    .gridCards {
        grid-template-columns: 1fr;
    }
    .wrapperMin .gridCards {
        grid-gap: 5px;
    }
}

@media (max-width: 500px) {
    .gridCards {
        grid-gap: 15px;
    }
    .wrapperMin .gridCards {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}
