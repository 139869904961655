.phoneMenu {
    grid-area: phoneMenu1;
    width: 100%;
    height: auto;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    display: grid;
    justify-items: center;
    z-index: 999;
}

.phoneMenu span {
    padding: 20px;
}

.phoneMenu-enter {
    opacity: 1;
    transform: scale(1.9);
    width: 100%;
    position: fixed;
    top: -2000px;
    left: 0;
}
.phoneMenu-enter-active {
    opacity: 1;
    transform: translateX(0);
    top: 60px;
    transition: opacity 1300ms, transform 300ms, width 300ms, top 300ms;
}
.phoneMenu-exit {
    opacity: 1;
}
.phoneMenu-exit-active {
    opacity: 0;
    transform: scale(1.9);
    transition: opacity 300ms, transform 300ms;
}


.cartMenu {
    grid-area: phoneMenu1;
    width: 260px;
    height: 300px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255,255,255,0.95);
    color: black;
    display: grid;
    grid-template-rows: 40px auto 20px 40px;
    align-items: center;
    justify-items: center;
    z-index: 999;
    border: 1px solid #1C1C1C;
}

.cartMenu span {
    padding-top: 20px;
}

.cartMenu a,
.cartMenu a:visited {
    color: #9D0917;
}

.cartMenu a:hover,
.cartMenu a:active {
    color: #6e0f0f;
    text-decoration: underline;
}

.cartMenu-enter {
    opacity: 1;
    transform: scale(1.9);
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
}
.cartMenu-enter div {
    opacity: 0;
}
.cartMenu-enter-active {
    opacity: 1;
    transform: translateX(0);
    top: 0;
    height: 300px;
    width: 260px;
    transition: opacity 500ms, transform 300ms, width 200ms, height 1000ms;
}
.cartMenu-enter-active div {
    opacity: 0;
    transition: opacity 1000ms;
}
.cartMenu-exit {
    height: 300px;
    width: 260px;
    opacity: 1;
}
.cartMenu-exit div {
    opacity: 1;
}
.cartMenu-exit-active {
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 300ms, width 300ms, height 300ms;
}
.cartMenu-exit-active div {
    opacity: 0;
    transition: opacity 100ms;
}
