.formControl.error input,
.formControl.error textarea {
    border: 3px solid red;
}

.formControl.error span {
    color: red;
}

.formSummaryError {
    border: 3px solid red;
    padding: 5px;
    color: red;
}

