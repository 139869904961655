.wrapper {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: auto auto auto;
    overflow-y: visible!important;
}

.items {

}

@media (max-width: 500px) {
    .wrapper {
        grid-template-columns: auto auto;
    }
}
