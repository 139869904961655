.wrapper {

}

.wrapper h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 30px;
}

.wrapper h2 {
    font-size: 1.8em;
    text-align: center;
    margin: 30px 0;
}

.wrapper p {
    font-size: 1em;
    margin: 10px;
}

.center {
    display: grid;
    justify-items: center;
    text-align: center;
}

.phonesBlock p {
    text-align: center;
    display: flex;
    justify-content: center;
}

.categoryList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 20px;
}

.categoryItem {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 20px;
    grid-gap: 10px;
    align-items: center;
    justify-items: center;
}

.wrapper li {
    list-style: none;
}

.wrapper a,
.wrapper a:visited {
    color: #6e0f0f;
}
.wrapper a:hover,
.wrapper a:active {
    color: #9D0917;
    text-decoration: underline;
}

.wrapper .svg {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    vertical-align: center;
}

.button {
    width: 300px;
    border-radius: 30px;
    background-color: #9D0917;
    border: 1px solid #B4B4B4;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.button:hover {
    background-color: #6e0f0f;
}

.buttonGroupFirst {
    border-radius: 30px 0 0 30px;
}

.buttonGroupSecond {
    width: 60px;
    background-color: green;
    border: 1px solid #B4B4B4;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 0 30px 30px 0;
}

.buttonGroupSecond:hover {
    background-color: darkgreen;
}

a.buttonGroupSecond,
a:visited.buttonGroupSecond,
a:hover.buttonGroupSecond  {
    color: white;
}

.buttonWhatsapp {
    display: grid;
    grid-template-columns: 300px 60px;
}


@media (max-width: 600px) {
    .categoryList {
        grid-template-columns: 1fr;
    }
}
