.wrapper {
    width: 100%;
    background: #fff;
    color: #1C1C1C;
    border-radius: 3px;
}

.wrapper2 {
    filter: opacity(0.6);
}

.wrapper:hover {
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}

.wrapper a,
.wrapper a:hover,
.wrapper a:visited {
    color: #04070F;
}

.cardContent {
    display: grid;
    grid-template-areas:
            "cardTitle"
            "cardArt"
            "cardImage"
            "cardPrice"
            "cardDate"
            "cardButton"
            "cardButtonWhatsapp"
            "cardDescription";
    grid-template-columns: 1fr;
    grid-template-rows: 10px 8px 200px 10px 10px;
    grid-gap: 15px;
    align-items: center;
    padding: 20px;
}

.cardTitle {
    grid-area: cardTitle;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.cardArt {
    grid-area: cardArt;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    text-overflow:ellipsis;
}

.cardImage {
    grid-area: cardImage;
    display: grid;
    width: 100%;
    align-items: center;
    justify-items: center;
}
.cardImage img {
    max-width: 100%;
    max-height: 200px;
}

.cardPrice {
    grid-area: cardPrice;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

.cardDate {
    grid-area: cardDate;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    font-family: 'Roboto';
    color: #707070;
}

.cardButton {
    grid-area: cardButton;
    justify-self: center;
}

.cardButton .buttonOff {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #4D5054;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.cardButton button {
    grid-area: cardButton;
    width: 160px;
    height: 40px;
    background: #D90015;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.cardButton button:focus {
    border: none;
    outline: none;
}

.cardButton button:hover {
    background: #9D0917;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}
.cardButton button:active {
    background: #9D0917;
    box-shadow: 0 0px 5px rgba(0,0,0,0.6);
    transition: background 0.4s, box-shadow 1s;
    border: 0;
    outline: none;
}

.cardButtonWhatsapp {
    grid-area: cardButtonWhatsapp;
    display: flex;
    justify-items: center;
    justify-content: center;
    justify-self: center;
    align-items: center;
    font-weight: 600;
}

.cardButtonWhatsapp svg {
    fill: green;
}

.cardDescription {
    grid-area: cardDescription;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #707070;
    height: 40px;
}


