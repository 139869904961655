.wrapper {
    width: 100%;
    display: grid;
    grid-template-areas:
            "productContent";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
}

.productContent {
    grid-area: productContent;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    display: grid;
    grid-template-areas:
            ". productTitle productTitle ."
            ". productImagesBlock productActionBlock ."
            ". productInfoBlock productInfoBlock .";
    grid-template-columns: 5% 45% 1fr 5%;
    grid-template-rows: 30px 1fr 1fr;
    grid-gap: 30px;
    padding: 50px 0;
}

.title {
    grid-area: productTitle;
    font-size: 22px;
    font-weight: 700;
}

.productImagesBlock {
    grid-area: productImagesBlock;
    width: 100%;
}

.productImagesBlock img {
    max-width: 100%;
}

.productActionBlock {
    grid-area: productActionBlock;
    width: 100%;
    display: grid;
    grid-template-areas:
            "price availability"
            "count count"
            "code code"
            "art art"
            "button button"
            "quick quick"
            "description description";
    grid-template-columns: 70% 30%;
    grid-template-rows: 40px 50px 20px 10px 60px 20px 1fr;
    grid-gap: 20px;
    align-items: center;
}

.quick {
    grid-area: quick;
}

.cardButtonWhatsapp {
    grid-area: cardButtonWhatsapp;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.cardButtonWhatsapp svg {
    fill: green;
}

.wrapper a,
.wrapper a:hover,
.wrapper a:visited {
    color: #04070F;
}

.productInfoBlock {
    grid-area: productInfoBlock;
}

.price {
    grid-area: price;
    font-size: 30px;
    font-weight: 700;
    align-self: end;
}
.availability {
    grid-area: availability;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto';
    align-self: end;
    justify-self: end;
}
.count {
    grid-area: count;
    height: 50px;
    border-top: 2px solid #04070F;
    border-bottom: 2px solid #04070F;
    font-size: 14px;
    font-weight: 600;
    display: grid;
    align-items: center;
}
.code {
    grid-area: code;
    font-size: 14px;
    font-weight: 600;
}

.art {
    grid-area: art;
    font-size: 14px;
    font-weight: 600;
}

.buttonOff {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #4D5054;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.button {
    grid-area: button;
    width: 160px;
    height: 40px;
    background: #D90015;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}

.button:focus {
    border: none;
    outline: none;
}

.button:hover,
.buttonOff:hover {
    background: #9D0917;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
}
.button:active {
    background: #9D0917;
    box-shadow: 0 0 5px rgba(0,0,0,0.6);
    transition: background 0.4s, box-shadow 1s;
    border: 0;
    outline: none;
}


.description {
    grid-area: description;
    align-self: start;
    font-size: 14px;
    font-weight: 600;
}


@media (max-width: 1050px) {
    .productContent {
        grid-template-areas:
                ". productTitle productTitle ."
                ". productImagesBlock productImagesBlock ."
                ". productActionBlock productActionBlock ."
                ". productInfoBlock productInfoBlock .";
        grid-template-columns: 5% 45% 1fr 5%;
        grid-template-rows: 30px 1fr 0.5fr 1fr;
    }
}

@media (max-width: 500px) {
    .productContent {
        grid-template-rows: 50px 1fr 0.5fr 1fr;;
    }
}
