@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

.wrapper {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    background: #EFF3F6;
    color: #000;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-areas:
            "header"
            "mainWrapper"
            "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 160px 1fr auto;
    grid-gap: 50px;
}

.header {
    grid-area: header;
}

.footer {
    grid-area: footer;
}

.mainWrapper {
    grid-area: mainWrapper;
    display: grid;
    grid-template-areas:
                ". main1 .";
    grid-template-columns: 10% 1fr 10%;
}

.main {
    grid-area: main1;
}

@media (max-width: 1600px) {
    .mainWrapper {
        grid-template-columns: 5% 1fr 5%;
    }
}

@media (max-width: 1250px) {
    .mainWrapper {
        grid-template-columns: 3% 1fr 3%;
    }
}

@media (max-width: 500px) {
    .mainWrapper {
        grid-template-columns: 1% 1fr 1%;
        grid-gap: 10px;
    }
}
