.wrapper {
    width: 100%;
    display: grid;
    background: #fff;
    color: #1C1C1C;
    grid-template-areas:
            ". logo search phones .";
    grid-template-columns: 10% 150px 2fr 1fr 10%;
    grid-template-rows: 100px;
    grid-gap: 20px;
    align-items: center;
}

.logo {
    grid-area: logo;
}

.search {
    grid-area: search;
    padding: 0 30px;
}

.search input {

}

.phones {
    grid-area: phones;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.phones a,
.phones a:visited {
    color: #04070F;
}

.phones a:hover,
.phones a:active {
    color: #9D0917;
    text-decoration: underline;
}


@media (max-width: 1450px) {
    .wrapper {
        grid-template-columns: 10% 150px 1.5fr 1fr 10%;
    }
}

@media (max-width: 1250px) {
    .wrapper {
        grid-template-columns: 10% 150px 2fr 1fr 10%;
    }
    .phones {
        grid-gap: 5px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}



@media (max-width: 900px) {
    .wrapper {
        grid-template-areas:
                ". logo search .";
        grid-template-columns: 4% 150px 1fr 4%;
    }
    .phones {
        display: none;
    }
}

@media (max-width: 600px) {
    .wrapper {
        grid-template-areas:
                "logo";
        grid-template-columns: 1fr;
        align-self: center;
        justify-self: center;
        text-align: center;
        margin-top: 160px;
    }
    .search {
        display: none;
    }
}
