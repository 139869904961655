html,
body,
* {
  padding: 0;
  margin: 0;
}

.adminWrapper {
  font-family: Roboto;
  overflow-x: hidden;
  background: #3c3e41;
  color: #fff;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-areas:
          "leftPanel leftBlock header"
          "leftPanel leftBlock main";
  grid-template-columns: 80px 260px 1fr;
  grid-template-rows: 80px 1fr;
  grid-gap: 1px;
}

header {
  grid-area: header;
  background: #04070F;
  border-bottom: 1px solid #4D5054;
}

menu {
  grid-area: menu;
}

menu ul li {
  display: inline;
  list-style-position: inside;
  list-style-type: none;
}

.leftPanel {
  grid-area: leftPanel;
  background: #04070F;
  border-right: 1px solid #4D5054;
}

.leftBlock {
  grid-area: leftBlock;
  display: grid;
  grid-template-areas:
          "leftBlockAvatarZone"
          "leftBlockMenu";
  grid-template-columns: 260px;
  grid-template-rows: 150px 1fr;
}

main {
  grid-area: main;
  background: rgb(4,18,29);
  background: linear-gradient(180deg, rgba(4,18,29,0.8) 0%, rgba(0,96,176,0.8) 66%, rgba(0,168,242,0.8) 100%);
  padding: 3% 10%;
}

.mainBlock {
  height: 100%;
  background: #282D3F;
  border: 1px solid #69748F;
  box-shadow: 15px 15px 25px rgba(0,0,0,0.4);
  display: grid;
  grid-template-areas:
          "mainBlockHeader"
          "mainBlockContent"
          "mainBlockFooter";
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 0px;
  grid-gap: 20px;
}

.mainBlockHeader {
  grid-area: mainBlockHeader;
  display: grid;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: center;
  background: rgba(0,142,206,0.45);
  font-size: 16px;
}

.mainBlockContent {
  grid-area: mainBlockContent;
  display: grid;
  grid-template-areas:
          "mainBlockLeft mainBlockRight";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.mainBlockLeft {
  grid-area: mainBlockLeft;
  border-right: 1px solid #535561;
  padding: 0 30px;
  display: grid;
  grid-template-areas:
          "buttonSwitch mainBlockRight"
          "buttonSwitch mainBlockRight";
  grid-template-columns: 40px 1fr;
  grid-template-rows: 120px 1fr;
}

.buttonSwitch {
  grid-area: buttonSwitch;
  width: 40px;
  height: 100%;
  background: rgba(0,142,206,0.45);
  margin-left: -50px;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.3);
}
.buttonSwitch:hover {
  background: rgba(9,35,41,0.70);
}

.buttonSwitch:hover svg {
  animation-name: rotation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.buttonSwitch:active {
  background: rgba(9,35,41,0.9);
  box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
}

@keyframes rotation {
  0% {
    transform:rotate(360deg);
  }
  100% {
    transform:rotate(0deg);
  }
}

.searchBlock {
  grid-area: searchBlock;
}

.demandsBlock {
  grid-area: demandsBlock;
}

.mainBlockRight {
  grid-area: mainBlockRight;
  padding: 0 30px;
}

.mainBlockFooter {
  grid-area: mainBlockFooter;
  justify-self: center;
  align-self: center;
}

.mainBlockButton {
  background: #D52945;
  border: 1px solid #D52945;
  border-radius: 30px;
  width: 190px;
  height: 50px;
  color: white;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  outline: none;
  outline-offset: 0;
}

.mainBlockButton:hover {
  background: #801F2F;
}

footer {
  grid-area: footer;
  background: #000;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

@media (max-width: 1780px) {
  main {
    padding: 3% 5%;
  }
}

@media (max-width: 1620px) {
  main {
    padding: 3% 3%;
  }
}

@media (max-width: 1560px) {
  main {
    padding: 3% 3%;
  }
  .mainBlockContent {
    grid-template-columns: 0.8fr 1.2fr;
  }
}

@media (max-width: 1440px) {
  .mainBlockContent {
    grid-template-columns: 0.6fr 1fr;
  }
}
