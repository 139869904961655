.srez {
    border-bottom: 1px dashed #4D5054;
    width: 2000%;
    position: relative;
    bottom: 21px;
    left: -5000px;
    overflow: hidden;
}

.expand {
    bottom: 10px;
    border: 0 solid #B4B4B4;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    /* display: flex; */
    width: 130px;
    /* justify-self: right; */
    right: 0;
    left: 100%;
    padding: 3px 3px 3px 3px;
    font-size: 12px;
    font-family: 'Roboto';
    color: #707070;
    background: white;
    justify-self: end;
    margin-right: 150px;
    text-align: center;
}

.expand:hover {
    color: #9D0917;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0,0,0,0.4);
}

.wrapper {
    width: 100%;
    position: relative;
    bottom: 20px;
    display: grid;
}

.filtersBlock {
    background-color: #ffffff;
    display: grid;
    grid-template-areas:
            "filtersLabel sort"
            "activeFilters sort";
    grid-template-columns: 1fr auto;
    grid-template-rows: 10px 1fr;
    grid-gap: 40px;
    justify-items: start;
    padding: 20px;
}

.filtersBlockHide {
    display: none;
}

.filtersLabel {
    grid-area: filtersLabel;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    align-items: center;
}

.activeFilters {
    grid-area: activeFilters;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
}

.sort {
    grid-area: sort;
    width: 100%;
    align-self: end;
    justify-self: end;
    text-align: right;
}

.activeFilters span {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    align-items: center;
}
.activeFilters span span {
    flex: 1 1 1;
    margin: 3px 3px;
}

.vendorsBlock {
    min-width: 200px;
    height: auto;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    padding: 20px;
    position: relative;
}

.vendorsTitle {
    font-family: 'Roboto';
    font-size: 12px;
    text-transform: uppercase;
    background: white;
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 5px;
}

.filterReload {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-size: 10px;
    text-transform: uppercase;
    background: white;
    position: absolute;
    top: -12px;
    right: 20px;
    padding: 0 5px;
    color: #9D0917;
    cursor: pointer;
}

@media (max-width: 700px) {
    .filtersBlock {
        grid-template-areas:
                "filtersLabel"
                "activeFilters"
                "sort";
        grid-template-columns: 1fr;
        grid-template-rows: 20px 1fr 120px;
    }
    .sort {
        justify-self: center;
        text-align: center;
    }
}
