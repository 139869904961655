.imageBlock {
    display: block;
}

.imageMobileBlock {
    display: none;
}

.imageMobileBlock img {
    align-self: center;
    vertical-align: center;
    max-height: 300px;
}

@media (max-width: 600px) {
    .imageBlock {
        display: none!important;
    }

    .imageMobileBlock {
        display: block;
    }
}
