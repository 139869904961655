.wrapper {
    width: 100%;
    background: #1C1C1C;
    color: #fff;
    display: grid;
    grid-template-areas:
            ". footerColumn1 footerColumn2 footerColumn3 footerColumn4 .";
    grid-template-columns: 10% 1fr 1fr 1fr 1fr 10%;
    grid-template-rows: 120px;
    grid-gap: 10px;
    align-items: center;
    padding: 20px 0;
}

.wrapper span {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
}

.footerColumn1 {
    grid-area: footerColumn1;
}
.footerColumn2 {
    grid-area: footerColumn2;
}
.footerColumn3 {
    grid-area: footerColumn3;
}
.footerColumn4 {
    grid-area: footerColumn4;
}

.footerColumn2 a:hover,
.footerColumn2 a:active {
    text-decoration: underline;
}
.footerColumn3 a:hover,
.footerColumn3 a:active {
    text-decoration: underline;
}

@media (max-width: 1250px) {
    .wrapper {
        grid-template-areas:
                ". footerColumn2 footerColumn1 ."
                ". footerColumn3 footerColumn4 .";
        grid-template-columns: 10% 1fr 1fr 10%;
        grid-template-rows: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .wrapper {
        grid-template-areas:
                ". footerColumn2 ."
                ". footerColumn3 ."
                ". footerColumn1 ."
                ". footerColumn4 .";
        grid-template-columns: 10% 1fr 10%;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        justify-items: center;
    }
}
