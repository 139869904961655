.wrapper {
    width: 100%;
    display: grid;
    grid-template-areas:
            "gridProductCards"
            "paginator";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 20px;
    grid-gap: 0;
    justify-items: center;
}

.productLoader {
    grid-area: productLoader;
}

.title {
    grid-area: title;
}

.breadcrumbs {
    grid-area: breadcrumbs;
    justify-self: start;
    align-self: center;
}

.breadcrumbs a,
.breadcrumbs a:visited,
.breadcrumbs a:active {
    color: rgba(0, 0, 0, 0.54);
}

.breadcrumbs a:hover {
    color: rgba(217,0,21,.9);
}

.gridProductCards {
    grid-area: gridProductCards;
    /*background: #fff;*/
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    justify-items: center;
    border-radius: 4px;
}

.gridMobileCards {
    grid-area: gridProductCards;
    display: none;
    width: 100%;
}

.paginator {
    grid-area: paginator;
    padding-top: 20px;
}

.notFound {
    align-self: center;
    justify-self: center;
    font-weight: 600;
}

@media (max-width: 1250px) {
    .gridProductCards {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 900px) {
    .gridProductCards {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .gridProductCards {
        grid-template-columns: 1fr;
        grid-gap: 15px;
        display: none;
    }
    .gridMobileCards {
        grid-area: gridProductCards;
        display: block;
    }
}
